import {
  Home,
  Classes,
  Login,
  CertifyEmail,
  ChangePw,
  Students,
  Etests,
  Books,
  BookDetail,
  StudentLogin,
  StudentClasses,
  StudentMain,
  StudentBookDetail,
  BookRecommend,
  BookRecommendLq,
  BookLibMine,
  BookRecommendDetail,
  BookLibMineDetail,
} from 'pages';

export const sitemap = [
  { path: '/', name: '홈', component: Home, authority: false, exact: true },
  {
    path: '/login',
    name: '로그인',
    component: Login,
    authority: false,
    exact: true,
  },
  {
    path: '/certifyEmail',
    name: '이메일 체크',
    component: CertifyEmail,
    authority: false,
    exact: true,
  },
  {
    path: '/changePw',
    name: '암호변경',
    component: ChangePw,
    authority: false,
    exact: true,
  },
  {
    path: '/pages/manager',
    name: '교사',
    component: Classes,
    authority: true,
    exact: true,
    gradeLimit: 2,
  },
  {
    path: '/pages/manager/users',
    name: '학생관리',
    component: Students,
    authority: true,
    exact: false,
    gradeLimit: 2,
  },
  {
    path: '/pages/manager/etests',
    name: '독서 검사 관리',
    component: Etests,
    authority: true,
    exact: false,
    gradeLimit: 2,
  },
  {
    path: '/pages/manager/books',
    name: '도서 관리',
    component: Books,
    authority: true,
    exact: true,
    gradeLimit: 2,
  },
  {
    path: '/pages/manager/books/bookdetail',
    name: '도서 상세페이지',
    component: BookDetail,
    authority: true,
    exact: true,
    gradeLimit: 2,
  },
  {
    path: '/pages/classe',
    name: '학급선택',
    component: StudentClasses,
    authority: false,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/classe/login',
    name: '로그인',
    component: StudentLogin,
    authority: false,
    exact: true,
  },
  {
    path: '/pages/student',
    name: '학생',
    component: StudentMain,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/student/bookdetail',
    name: '도서 상세페이지',
    component: StudentBookDetail,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/bookrecommend',
    name: '선생님 추천도서',
    component: BookRecommend,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/bookrecommend/bookdetail',
    name: '도서 상세페이지',
    component: BookRecommendDetail,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/booklqrecommend',
    name: '수준별 추천도서',
    component: BookRecommendLq,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/booklqrecommend/bookdetail',
    name: '도서 상세페이지',
    component: BookRecommendDetail,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/booklibmine',
    name: '내 서재',
    component: BookLibMine,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
  {
    path: '/pages/booklibmine/bookdetail',
    name: '도서 상세페이지',
    component: BookLibMineDetail,
    authority: true,
    exact: true,
    gradeLimit: 3,
  },
];
