import { createAction, handleActions } from 'redux-actions';

const SELECTED_BOOK = 'class/SELECTED_BOOK';

export const selectedBook = createAction(SELECTED_BOOK, (item) => item);

const initialState = {
  recommendBooks: [],
  selectedBook: null,
};

const books = handleActions(
  {
    [SELECTED_BOOK]: (state, { payload: item }) => ({
      ...state,
      selectedBook: { ...item },
      recommendBooks: [...new Set([...state.recommendBooks, item.isbn13])],
    }),
  },
  initialState,
);

export default books;
