import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as groupAPI from 'lib/api/group';

import createRequestSaga, {
  createRequestActionTypes,
} from 'lib/createRequestSaga';

import produce from 'immer';

const CHANGE_GROUP = 'manager/group/CHANGE_GROUP';
const [FIND_BY_USER, FIND_BY_USER_SUCCESS] = createRequestActionTypes(
  'manager/group/FIND_BY_USER',
);

export const change = createAction(
  CHANGE_GROUP,
  selectedGroup => selectedGroup,
);

export const findByUser = createAction(FIND_BY_USER, user => user);

const findByUserSaga = createRequestSaga(FIND_BY_USER, groupAPI.findByUser);

export function* groupSaga() {
  yield takeLatest(FIND_BY_USER, findByUserSaga);
}

const initialState = {
  selectedGroup: 0,
  groups: null,
};

export default handleActions(
  {
    [CHANGE_GROUP]: (state, { payload: selectedGroup }) =>
      produce(state, draft => {
        draft.selectedGroup = selectedGroup;
      }),
    [FIND_BY_USER_SUCCESS]: (state, { payload: groups }) => ({
      ...state,
      groups: groups.groups,
    }),
  },
  initialState,
);
