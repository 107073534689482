export const hideStrOverLimitCount = (strTxt, limitCount) => {
  let returnStr = '';

  if (strTxt.length > limitCount) {
    returnStr = strTxt.substring(0, limitCount) + '...';
  } else {
    returnStr = strTxt;
  }

  return returnStr;
};

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i) == null ? false : true;
  },
  IOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null
      ? false
      : true;
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i) == null ? false : true;
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) == null ? false : true;
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.IOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const setLoginEmail = (email) => {
  window.localStorage.setItem('loginEmailForReadAndWrite', email);
};

export const getLoginEmail = () => {
  const emailData = window.localStorage.getItem('loginEmailForReadAndWrite');
  if (emailData !== undefined && emailData !== null && emailData !== '') {
    return emailData;
  } else {
    return '';
  }
};

export const setAuthKey = (authKey) => {
  window.localStorage.setItem('readAndWriteUserAuthkey', authKey);
};

export const getAuthKey = () => {
  const authKey = window.localStorage.getItem('readAndWriteUserAuthkey');
  if (authKey !== undefined && authKey !== null && authKey !== '') {
    return authKey;
  } else {
    return '';
  }
};
export const book_graphql_uri = 'http://106.10.50.220:4000';
export const graphql_uri = '';
//export const graphql_uri = 'http://localhost:4000';
//export const image_uri = 'http://localhost:5000';
//export const image_uri = '';
export const image_uri = 'http://natmal.com';

export const isObject = (val) => {
  return val instanceof Object;
};

//정보나루 검색코드
export const searchs = [
  {
    name: '지역',
    value: 0,
    subItems: [
      { name: '전국', value: 0 },
      { name: '서울', value: 11 },
      { name: '부산', value: 21 },
      { name: '대구', value: 22 },
      { name: '인천', value: 23 },
      { name: '광주', value: 24 },
      { name: '대전', value: 25 },
      { name: '울산', value: 26 },
      { name: '세종', value: 29 },
      { name: '경기', value: 31 },
      { name: '강원', value: 32 },
      { name: '충북', value: 33 },
      { name: '충남', value: 34 },
      { name: '전북', value: 35 },
      { name: '전남', value: 36 },
      { name: '경북', value: 37 },
      { name: '경남', value: 38 },
      { name: '제주', value: 39 },
    ],
  },
  {
    name: '연령',
    value: 1,
    subItems: [
      { name: '영유아(0~5세)', value: 0 },
      { name: '유아(6~7세)', value: 6 },
      { name: '초등(8~13세)', value: 8 },
      { name: '청소년(14~19세)', value: 14 },
      { name: '20대', value: 20 },
      { name: '30대', value: 30 },
      { name: '40대', value: 40 },
      { name: '50대', value: 50 },
      { name: '60세 이상', value: 60 },
      { name: '미상', value: -1 },
    ],
  },
  {
    name: '주제',
    value: 2,
    subItems: [
      { name: '총류', value: 0 },
      { name: '철학', value: 1 },
      { name: '종교', value: 2 },
      { name: '사회과학', value: 3 },
      { name: '자연과학', value: 4 },
      { name: '기술과학', value: 5 },
      { name: '예술', value: 6 },
      { name: '언어', value: 7 },
      { name: '문학', value: 8 },
      { name: '역사', value: 9 },
    ],
  },
];
