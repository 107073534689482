import React from 'react';
import Loadable from 'react-loadable';

const Loading = () => {
  return (
    <div id="fuse-splash-screen">
      <div className="center">
        <div className="logo">
          <img width="128" src="/assets/logo/Logo_50_C.png" alt="logo" />
        </div>
        <br />
        <div className="loading"></div>
      </div>
    </div>
  );
};

export const Home = Loadable({
  loader: () => import('./home'),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import('./auth/login'),
  loading: Loading,
});

export const StudentLogin = Loadable({
  loader: () => import('./auth/students/login'),
  loading: Loading,
});

export const CertifyEmail = Loadable({
  loader: () => import('./auth/certifyEmail'),
  loading: Loading,
});

export const ChangePw = Loadable({
  loader: () => import('./auth/changePw'),
  loading: Loading,
});

/** 지도교사 */
export const Classes = Loadable({
  loader: () => import('./manager/classes'),
  loading: Loading,
});

export const Students = Loadable({
  loader: () => import('./manager/students'),
  loading: Loading,
});

export const Etests = Loadable({
  loader: () => import('./manager/etests'),
  loading: Loading,
});

export const Books = Loadable({
  loader: () => import('./manager/books'),
  loading: Loading,
});

export const BookDetail = Loadable({
  loader: () => import('./manager/bookDetail'),
  loading: Loading,
});

/** 학생 */
export const StudentClasses = Loadable({
  loader: () => import('./students/classes'),
  loading: Loading,
});

export const StudentMain = Loadable({
  loader: () => import('./students/main'),
  loading: Loading,
});

export const StudentBookDetail = Loadable({
  loader: () => import('./students/bookDetail'),
  loading: Loading,
});

export const BookRecommend = Loadable({
  loader: () => import('./books/bookRecommend'),
  loading: Loading,
});

export const BookRecommendDetail = Loadable({
  loader: () => import('./books/bookDetail'),
  loading: Loading,
});

export const BookRecommendLq = Loadable({
  loader: () => import('./books/bookRecommendLq'),
  loading: Loading,
});

export const BookLibMine = Loadable({
  loader: () => import('./mine/bookLibMine'),
  loading: Loading,
});

export const BookLibMineDetail = Loadable({
  loader: () => import('./mine/bookDetail'),
  loading: Loading,
});
