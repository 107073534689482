import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeTemplate from 'components/layouts/HomeTemplate';
import { Result, Button } from 'antd';

/*
export default function RequireAuth({ children, history }) {
  // If user not authenticated render out to root

  useEffect(() => {
    if (!auth) {
      history.push({
        pathname: '/login',
        state: { from: history.location },
      });
    }
  }, [history, auth]);

  return <>{children}</>;
}
*/

export default function PrivateRoute({ component: Component, grade, ...rest }) {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  if (user != null) {
    if (user.group != null && user.group.grade <= grade) {
      return <Route {...rest} component={Component} />;
    } else {
      return (
        <Route
          component={() => {
            return (
              <HomeTemplate>
                <Result
                  status="403"
                  title="403"
                  subTitle="현재 권한으로 볼 수 없는 페이지 입니다."
                  extra={
                    <Button
                      type="primary"
                      onClick={() => (window.location.href = '/')}
                    >
                      홈으로
                    </Button>
                  }
                />
              </HomeTemplate>
            );
          }}
        />
      );
    }
  } else {
    return (
      <Route
        {...rest}
        component={(props) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location, type: grade },
            }}
          />
        )}
      />
    );
  }
}
