import { createAction, handleActions } from 'redux-actions';

const INITIAL_STUDENT = 'class/INITIAL_STUDENT';
const SIGNIN_STUDENT = 'class/SELECTED_STUDENT';

export const initialStudent = createAction(INITIAL_STUDENT);
export const signInStudent = createAction(SIGNIN_STUDENT, (row) => row);

const initialState = {
  signInStudent: null,
};

const student = handleActions(
  {
    [INITIAL_STUDENT]: (state) => ({ ...state, signInStudent: null }),
    [SIGNIN_STUDENT]: (state, { payload: row }) => ({
      ...state,
      signInStudent: { ...row },
    }),
  },
  initialState,
);

export default student;
