import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducers, { rootSaga } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { tempSetUser, check } from 'modules/manager/user';
import { findByUser } from 'modules/manager/group';

import { ApolloProvider } from 'react-apollo'; // 수정
import { ApolloClient } from 'apollo-client'; // 수정
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory'; // 수정
import { graphql_uri, book_graphql_uri } from 'utils/common';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from 'styles/theme';
import 'styles/globals';
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

function loadUser() {
  try {
    const user = window.localStorage.getItem('user');
    if (!user) return;
    store.dispatch(tempSetUser(user));
    store.dispatch(check());
    store.dispatch(findByUser(JSON.parse(user)));
  } catch (e) {
    console.log('localStorage is not working');
  }
}

// 2
const httpLink = createHttpLink({
  // 수정
  uri: `${graphql_uri}/graphql`, // 수정
  credentials: 'include',
}); // 수정

const httpLink2 = createHttpLink({
  // 수정
  uri: `${book_graphql_uri}/graphql`, // 수정
  credentials: 'include',
}); // 수정

// 3
const client = new ApolloClient({
  link: httpLink, // 수정
  cache: new InMemoryCache(), // 수정
});

sagaMiddleware.run(rootSaga);
loadUser();

const render = (Component) => {
  return ReactDOM.render(Component, document.getElementById('root'));
};

const GlobalStyle = createGlobalStyle`


`;

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
