import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import HomeHeader from './header/HomeHeader';
import Footer from './footer';

const HomeTemplateBlock = styled.div`
  min-width: 320px;
  height: 100%;
`;

const { Header, Content } = Layout;

const HomeTemplate = ({ children }) => {
  return (
    <HomeTemplateBlock>
      <Layout
        theme={'light'}
        style={{
          color: 'black',
          background: 'white',
          width: '100%',
          minWidth: '320px',
        }}
      >
        <Header style={{ color: 'black', background: 'white' }} />
        <Content
          style={{
            color: 'black',
            background: 'white',
            height: '100%',
          }}
        >
          <div
            style={{
              maxWidth: '1024px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <HomeHeader />
            {children}
          </div>
        </Content>
        <Footer />
      </Layout>
    </HomeTemplateBlock>
  );
};

export default HomeTemplate;
