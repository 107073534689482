import client from './client';

export const login = ({ email, password, grpId, type }) =>
  client.post('/api/login', { email, password, grpId, type });
export const join = ({ name, email, phone, password, grpId, type }) =>
  client.post('/api/join', {
    name,
    email,
    phone,
    password,
    grpId,
    type,
    siteId: sessionStorage.getItem('siteId'),
  });
export const joinGroup = ({ userEmail, password, groupId }) =>
  client.post('/api/joinGroup', {
    userEmail,
    password,
    groupId,
  });

export const certify = ({ email }) =>
  client.post('/api/certify', {
    email,
    grpId: sessionStorage.getItem('grpId'),
    siteId: sessionStorage.getItem('siteId'),
  });
export const checkCert = ({ secretId, secretKey }) =>
  client.post('/api/checkCertEmail', { secretId, secretKey });
export const find = ({ email }) =>
  client.post('/api/find', {
    email,
    grpId: sessionStorage.getItem('grpId'),
    siteId: sessionStorage.getItem('siteId'),
  });
export const change = ({ secretId, secretKey, password }) =>
  client.post('/api/change', { secretId, secretKey, password });
export const checkOverlap = ({ userId, userAuthKey }) =>
  client.post('/api/checkUserAuthKeyInfo', { params: { userId, userAuthKey } });
export const updateOverlap = ({ userId, userAuthKey }) =>
  client.post('/api/updateUserConnectInfo', {
    params: { userId, userAuthKey },
  });
export const confirmUserPw = ({ userId, userPw }) =>
  client.post('/api/groups/users/checkUserPw', { params: { userId, userPw } });
export const updateUserInfo = ({ userId, name, phone, password }) =>
  client.post('/api/groups/users/updateUserInfo', {
    params: { userId, name, phone, password },
  });
export const check = () => client.get('/api/validate');
export const logout = () => client.get('/api/logout');
