import { createAction, handleActions } from 'redux-actions';
import { takeLatest, call } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from 'lib/createRequestSaga';

import * as authAPI from 'lib/api/auth';

const TEMP_SET_USER = 'manager/user/TEMP_SET_USER';
const LOGOUT = 'manager/user/LOGOUT';
const CONFIRM_INIT = 'manager/user/CONFIRM_INIT';
const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes('manager/user/CHECK');
const [CHECK_OVERLAP, CHECK_OVERLAP_SUCCESS, CHECK_OVERLAP_FAILURE] = createRequestActionTypes('manager/user/CHECK_OVERLAP');
const [UPDATE_OVERLAP, UPDATE_OVERLAP_SUCCESS, UPDATE_OVERLAP_FAILURE] = createRequestActionTypes('manager/user/UPDATE_OVERLAP');
const [CONFIRM_PW, CONFIRM_PW_SUCCESS, CONFIRM_PW_FAILURE] = createRequestActionTypes('manager/user/CONFIRM_PW');
const [UPDATE_USERINFO, UPDATE_USERINFO_SUCCESS, UPDATE_USERINFO_FAILURE] = createRequestActionTypes('manager/user/UPDATE_USERINFO');

export const check = createAction(CHECK);
export const logout = createAction(LOGOUT);
export const tempSetUser = createAction(TEMP_SET_USER, (user) => user);
export const confirmUserInit = createAction(CONFIRM_INIT);
export const checkUserPw = createAction(
  CONFIRM_PW,
  ({ userId, userPw }) => ({
    userId,
    userPw,
  }),
);
export const checkOverlapUser = createAction(
  CHECK_OVERLAP,
  ({ userId, userAuthKey }) => ({
    userId,
    userAuthKey,
  }),
);
export const updateOverlapUser = createAction(
  UPDATE_OVERLAP,
  ({ userId, userAuthKey }) => ({
    userId,
    userAuthKey,
  }),
);
export const updateUserInfo = createAction(
  UPDATE_USERINFO, 
  ({ userId, name, phone, password }) => ({
    userId,
    name,
    phone,
    password,
  })
);

const checkSaga = createRequestSaga(CHECK, authAPI.check);
const checkOverlapSaga = createRequestSaga(CHECK_OVERLAP, authAPI.checkOverlap);
const updateOverlapSaga = createRequestSaga(UPDATE_OVERLAP, authAPI.updateOverlap);
const confirmUserPwSaga = createRequestSaga(CONFIRM_PW, authAPI.confirmUserPw);
const updateUserInfoSaga = createRequestSaga(UPDATE_USERINFO, authAPI.updateUserInfo);

function checkFailureSaga() {
  try {
    window.localStorage.removeItem('user');
  } catch (e) {
    console.log('localStorage is not working');
  }
}

function* logoutSaga() {
  try {
    yield call(authAPI.logout);
    window.localStorage.removeItem('user');
    console.log('logoutSaga');
  } catch (e) {
    console.log(e);
  }
}

export function* userSaga() {
  yield takeLatest(CHECK, checkSaga);
  yield takeLatest(CHECK_FAILURE, checkFailureSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(CHECK_OVERLAP, checkOverlapSaga);
  yield takeLatest(UPDATE_OVERLAP, updateOverlapSaga);
  yield takeLatest(CONFIRM_PW, confirmUserPwSaga);
  yield takeLatest(UPDATE_USERINFO, updateUserInfoSaga);
}

const initialState = {
  user: null,
  checkError: null,
  userKey: null,
  userKeyError: null,
  confirmUserPw: null,
  confirmUserPwError: null,
  newUserInfo: null,
  newUserInfoError: null
};

export default handleActions(
  {
    [TEMP_SET_USER]: (state, { payload: user }) => ({
      ...state,
      user,
    }),
    [CHECK_SUCCESS]: (state, { payload: user }) => ({
      ...state,
      user: user.user,
      checkError: null,
    }),
    [CHECK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      user: null,
      checkError: error,
    }),
    [CHECK_OVERLAP_SUCCESS]: (state, { payload: userKey }) => ({
      ...state,
      userKeyError: null,
      userKey: userKey,
    }),
    [CHECK_OVERLAP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      userKeyError: error,
    }),
    [UPDATE_OVERLAP_SUCCESS]: (state, { payload: userKey }) => ({
      ...state,
      userKeyError: null,
      userKey: userKey,
    }),
    [UPDATE_OVERLAP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      userKeyError: error,
    }),
    [CONFIRM_PW_SUCCESS]: (state, { payload: confirmUserPw }) => ({
      ...state,
      confirmUserPwError: null,
      confirmUserPw,
    }),
    [CONFIRM_PW_FAILURE]: (state, { payload: confirmUserPwError }) => ({
      ...state,
      confirmUserPwError,
    }),
    [UPDATE_USERINFO_SUCCESS]: (state, { payload: newUserInfo }) => ({
      ...state,
      newUserInfoError: null,
      newUserInfo,
    }),
    [UPDATE_USERINFO_FAILURE]: (state, { payload: newUserInfoError }) => ({
      ...state,
      newUserInfoError,
    }),
    [CONFIRM_INIT]: (state) => ({
      ...state,
      confirmUserPw: null,
      confirmUserPwError: null,
      newUserInfo: null,
      newUserInfoError: null
    }),
    [LOGOUT]: (state) => ({
      ...state,
      user: null,
      checkError: null,
      userKey: null,
      userKeyError: null,
      confirmUserPw: null,
      confirmUserPwError: null,
      newUserInfo: null,
      newUserInfoError: null
    }),
  },
  initialState,
);
