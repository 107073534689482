import auth, { authSaga } from 'modules/commons/auth';
import user, { userSaga } from 'modules/commons/user';
import group, { groupSaga } from 'modules/manager/group';
import classe from 'modules/commons/class';
import student from 'modules/commons/student';
import books from 'modules/commons/books';
import loading from 'modules/commons/loading';
import { all } from 'redux-saga/effects';

import { combineReducers } from 'redux';

const reducers = combineReducers({
  auth,
  user,
  group,
  loading,
  classe,
  student,
  books,
});

export function* rootSaga() {
  yield all([authSaga(), userSaga(), groupSaga()]);
}

export default reducers;
