import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Typography, BackTop } from 'antd';

const { Paragraph, Text } = Typography;
const { Footer } = Layout;
const Contents = ({ history }) => {
  return (
    <Footer
      style={{
        position: 'relative',
        clear: 'both',
        color: '#666666',
        backgroundColor: '#f6f6f6',
        minWidth: '320px',
        fontSize: '12px',
        fontFamily:
          '맑은고딕, 돋움, Lucida Grande, Lucida Sans, Arial, sans-serif',
        lineHeight: '17px',
        letterSpacing: '0',
      }}
    >
      <BackTop />
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Row
            type="flex"
            justify="center"
            gutter={[24, 24]}
            align="middle"
            style={{ margin: 'auto' }}
          >
            <Col>
              <Link
                to="#"
                onClick={() => {
                  window.location.href = 'https://www.natmal.com';
                }}
              >
                <img width="128" src="/assets/logo/Logo_50_C.png" alt="logo" />
              </Link>
            </Col>
            <Col flex="auto">
              <Col>
                <Paragraph>
                  상호/대표자명: (주)낱말/김기형 사업자등록번호: 220-87-16688
                  통신판매업신고번호: 제 2018-서울송파-0463 <br />
                  주소: 서울특별시 송파구 법원로 114 엠스테이트 B동
                  1102호(문정동)
                  <br />
                  전화번호/이메일: 02-3463-2321/mail@natmal.com 팩스:
                  02-3463-6123
                </Paragraph>
                <Text strong>Copyright © Natmal Corp. 2018</Text>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};

export default withRouter(Contents);
