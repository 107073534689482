import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PrivateRoute from 'components/commons/auth/PrivateRoute';
import StudentRoute from 'components/commons/auth/StudentRoute';
import HomeTemplate from 'components/layouts/HomeTemplate';
import { Result, Button, notification, Progress } from 'antd';
import * as siteApi from 'lib/api/site';
import './App.css';
import { useState } from 'react';
import { useEffect } from 'react';
import * as routers from 'pages/routes';
import { useDispatch } from 'react-redux';
import { check } from 'modules/commons/user';
import isElectron from 'is-electron';

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const [siteAuth, setSiteAuth] = useState(null);
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);
  const [updateNoClose, setUpdateNoClose] = useState(false);
  const [updateStep, setUpdateStep] = useState(1);
  const [downPercent, setDownPercent] = useState(0);

  const dispatch = useDispatch();
  const fetchSiteCheck = async (grpId, siteId) => {
    try {
      const result = await siteApi.checkSite(grpId, siteId);
      if (result.status === 200) {
        setSiteAuth('1');
        sessionStorage.setItem('grpId', grpId);
        sessionStorage.setItem('siteId', siteId);
        if (result.data.group.LOGO)
          sessionStorage.setItem('logo', result.data.group.LOGO);
        if (result.data.group.NAME)
          sessionStorage.setItem('groupName', result.data.group.NAME);
        sessionStorage.setItem(
          'schoolDiv',
          result.data.group.School.SCHOOL_GUBUN,
        );
      } else {
        setSiteAuth('0');
        sessionStorage.removeItem('grpId');
        sessionStorage.removeItem('siteId');
        sessionStorage.removeItem('logo');
        sessionStorage.removeItem('groupName');
        sessionStorage.removeItem('schoolDiv');
      }
    } catch (e) {
      console.log(e);
      setSiteAuth('0');
    }
  };

  useEffect(() => {
    try {
      let datas, grpId, siteId;
      if (isElectron()) {
        window.ipcRenderer.send('update-check-now', { download: false });
        window.ipcRenderer.on('updater-message', (event, args) => {
          switch (args.type) {
            case 'checking-for-update':
              console.log('서버에서 신규 버전이 있는지 확인 중입니다.');
              setMessage('서버에서 신규 버전이 있는지 확인 중입니다.');
              setVisible(true);
              setUpdateNoClose(false);
              break;
            case 'update-not-available':
              console.log('최신 버전의 애플리케이션을 사용 중입니다.');
              setMessage('최신 버전의 애플리케이션을 사용 중입니다.');
              setVisible(true);
              setUpdateNoClose(false);
              break;
            case 'update-available':
              const { version } = args.info;
              console.log('서버에서 신규 버전(' + version + ')을 찾았습니다.');
              setMessage(`서버에서 신규 버전 ${version}을 찾았습니다.`);
              setVisible(true);
              setUpdateNoClose(false);
              setUpdateStep(2);
              break;
            case 'download-progress':
              const {
                bytesPerSecond,
                percent,
                total,
                transferred,
                delta,
              } = args.progress;
              console.log(
                '업데이트 파일을 다운로드(' +
                  percent.toFixed(1) +
                  '%) 중입니다.',
              );
              setMessage(`업데이트 파일을 다운로드 중입니다.`);
              setDownPercent(percent.toFixed(1));
              setVisible(true);
              setUpdateNoClose(true);
              setUpdateStep(3);
              break;

            case 'update-downloaded':
              console.log('모든 업데이트 파일을 다운로드하였습니다.');
              setMessage('모든 업데이트 파일을 다운로드하였습니다.');
              setVisible(true);
              setUpdateNoClose(true);
              setDownPercent(100);
              setUpdateStep(4);
              break;
            case 'update-error':
              console.log('에러: ' + args.error);
              setMessage(`에러: ${args.error}`);
              setVisible(true);
              setUpdateNoClose(false);
              break;
            default:
              break;
          }
        });

        window.ipcRenderer.on('ssn', (event, arg) => {
          window.ipcRenderer.removeAllListeners('ssn');
          console.log(arg);
          grpId = arg.grpId;
          siteId = arg.siteId;
          if (siteId == null || grpId == null) {
            setSiteAuth('0');
          }
          fetchSiteCheck(grpId, siteId);
        });
        window.ipcRenderer.send('ssn', { call: 'ssn' });
      } else {
        if (location.search) {
          datas = location.search.split('&');
          grpId = datas.find((x) => x.indexOf('grpId') >= 0).split('=')[1];
          siteId = datas.find((x) => x.indexOf('siteId') >= 0).split('Id=')[1];
          // eslint-disable-next-line no-throw-literal
          if (siteId == null || grpId == null) {
            setSiteAuth('0');
          }
          fetchSiteCheck(grpId, siteId);
        } else {
          grpId = sessionStorage.getItem('grpId');
          siteId = sessionStorage.getItem('siteId');
          if (siteId == null || grpId == null) {
            setSiteAuth('0');
          }
          fetchSiteCheck(grpId, siteId);
        }
      }
    } catch (e) {
      console.log(e);
      setSiteAuth('0');
    }
  }, [location.search]);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      dispatch(check());
    }
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      const btn = [];
      console.log(updateStep);
      if (updateStep === 2) {
        btn.push(
          <Button
            type="primary"
            size="small"
            onClick={() => {
              window.ipcRenderer.send('update-download-now', {});
            }}
          >
            업데이트 다운로드
          </Button>,
        );
      }

      if (updateStep === 4) {
        btn.push(
          <Button
            type="primary"
            size="small"
            onClick={() => {
              console.log('Resart Now...');
              window.ipcRenderer.send('update-and-install-now', '');
            }}
          >
            지금 설치 및 재시작
          </Button>,
        );
        btn.push(' ');
        btn.push(
          <Button
            size="small"
            onClick={() => {
              console.log('Quit Now...');
              window.ipcRenderer.send('update-quit-now', '');
            }}
          >
            지금 종료
          </Button>,
        );
      }
      console.log(btn);
      notification.open({
        message: '알림',
        description:
          updateStep >= 3 ? (
            <div>
              <Progress percent={downPercent} />
              {message}
            </div>
          ) : (
            message
          ),
        placement: 'bottomLeft',
        key: 1,
        duration: 0,
        btn,
        closeIcon: updateNoClose ? <div></div> : null,
      });
    }
  }, [visible, message, updateStep, updateNoClose, downPercent]);

  if (siteAuth != null) {
    return siteAuth === '1' ? (
      <div className="App">
        <Switch>
          {routers.sitemap.map((x) => {
            if (x.authority) {
              if (x.gradeLimit < 3) {
                return (
                  <PrivateRoute
                    key={x.path}
                    exact={x.exact}
                    path={x.path}
                    component={x.component}
                    grade={x.gradeLimit}
                  />
                );
              } else {
                return (
                  <StudentRoute
                    key={x.path}
                    exact={x.exact}
                    path={x.path}
                    component={x.component}
                    grade={x.gradeLimit}
                  />
                );
              }
            } else {
              return (
                <Route
                  key={x.path}
                  exact={x.exact}
                  path={x.path}
                  component={x.component}
                />
              );
            }
          })}
          <Route
            component={() => {
              return (
                <HomeTemplate>
                  <Result
                    status="404"
                    title="404"
                    subTitle="페이지가 존재 하지 안습니다."
                    extra={
                      <Button type="primary" onClick={() => history.goBack()}>
                        뒤로 가기
                      </Button>
                    }
                  />
                </HomeTemplate>
              );
            }}
          />
        </Switch>
      </div>
    ) : (
      <div className="App">
        <HomeTemplate>
          <Result
            status="403"
            title="403"
            subTitle="승인되지 않은 접근입니다."
          />
        </HomeTemplate>
      </div>
    );
  } else {
    return <div className="App"></div>;
  }
};

export default App;
