import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeTemplate from 'components/layouts/HomeTemplate';
import { ISLOGIN } from 'lib/query/userStudent';
import { useQuery } from '@apollo/react-hooks';
/*
export default function RequireAuth({ children, history }) {
  // If user not authenticated render out to root

  useEffect(() => {
    if (!auth) {
      history.push({
        pathname: '/login',
        state: { from: history.location },
      });
    }
  }, [history, auth]);

  return <>{children}</>;
}
*/

export default function StudentRoute({ component: Component, grade, ...rest }) {
  const location = useLocation();
  const { state } = location;
  const { loading, data, error } = useQuery(ISLOGIN);

  if (loading) return <p>Loading...</p>;
  if (data && data.isLogin) {
    return <Route {...rest} component={Component} />;
  } else {
    return (
      <Route
        {...rest}
        component={() => (
          <Redirect
            to={{
              pathname: '/pages/classe/login',
              state: { from: location, type: grade, ...state },
            }}
          />
        )}
      />
    );
  }
}
