import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Row, Col } from 'antd';
import { image_uri } from 'utils/common';
const { Title } = Typography;

const HomeHeader = () => {
  const logo = sessionStorage.getItem('logo');
  console.log(logo);
  const groupName = sessionStorage.getItem('groupName');
  return (
    <>
      <Row type="flex" justify="center">
        {logo && (
          <Col>
            <div className="logo">
              <Link to="/">
                <img
                  style={{
                    maxWidth: '200px',
                    maxHeight: '60px',
                  }}
                  src={`${image_uri}/upload/logo/${logo}`}
                  alt="logo"
                />
              </Link>
            </div>
          </Col>
        )}
        {logo == null && groupName && (
          <Col>
            <Link to="/">{groupName}</Link>
          </Col>
        )}
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <span>한 학기 한 권 읽기를 위한</span>
          <Title level={2}>독서 지원 시스템</Title>
        </Col>
      </Row>
    </>
  );
};

export default HomeHeader;
