import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestSaga, {
  createRequestActionTypes,
} from 'lib/createRequestSaga';

import * as authAPI from 'lib/api/auth';

/*
const CHANGE_FIELD = 'manager/auth/CHANGE_FIELD';
const INITIALIZE_FORM = 'manager/auth/INITIALIZE_FORM';
*/
const INITIALIZE_AUTH = 'auth/INITIALIZE_AUTH';
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes(
  'auth/LOGIN',
);
const [JOIN, JOIN_SUCCESS, JOIN_FAILURE] = createRequestActionTypes(
  'auth/JOIN',
);
const [CERTIFY, CERTIFY_SUCCESS, CERTIFY_FAILURE] = createRequestActionTypes(
  'auth/CERTIFY',
);
const [
  CHECK_CERT,
  CHECK_CERT_SUCCESS,
  CHECK_CERT_FAILURE,
] = createRequestActionTypes('auth/CHECK_CERT');
const [FIND, FIND_SUCCESS, FIND_FAILURE] = createRequestActionTypes(
  'auth/FIND',
);
const [
  CHANGE_PW,
  CHANGE_PW_SUCCESS,
  CHANGE_PW_FAILURE,
] = createRequestActionTypes('auth/CHANGE_PW');

/*
export const changeField = createAction(
  CHANGE_FIELD,
  ({ form, key, value }) => ({
    form,
    key,
    value,
  }),
);

export const initializeForm = createAction(INITIALIZE_FORM, form => form);
*/
export const initializeAuth = createAction(INITIALIZE_AUTH);
export const login = createAction(
  LOGIN,
  ({ email, password, grpId, type }) => ({
    email,
    password,
    grpId,
    type,
  }),
);
export const join = createAction(
  JOIN,
  ({ name, email, phone, password, grpId, type }) => ({
    name,
    email,
    phone,
    password,
    grpId,
    type,
  }),
);
export const certify = createAction(CERTIFY, ({ email }) => ({
  email,
}));
export const checkCertEmail = createAction(
  CHECK_CERT,
  ({ secretId, secretKey }) => ({
    secretId,
    secretKey,
  }),
);
export const find = createAction(FIND, ({ email }) => ({
  email,
}));
export const change = createAction(
  CHANGE_PW,
  ({ secretId, secretKey, password }) => ({
    secretId,
    secretKey,
    password,
  }),
);

const loginSaga = createRequestSaga(LOGIN, authAPI.login);
const joinSaga = createRequestSaga(JOIN, authAPI.join);
const certifySaga = createRequestSaga(CERTIFY, authAPI.certify);
const checkCertSaga = createRequestSaga(CHECK_CERT, authAPI.checkCert);
const findSaga = createRequestSaga(FIND, authAPI.find);
const changeSaga = createRequestSaga(CHANGE_PW, authAPI.change);

export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(JOIN, joinSaga);
  yield takeLatest(CERTIFY, certifySaga);
  yield takeLatest(CHECK_CERT, checkCertSaga);
  yield takeLatest(FIND, findSaga);
  yield takeLatest(CHANGE_PW, changeSaga);
}

const initialState = {
  /*
  login: {
    email: '',
    password: '',
  },
  */
  auth: null,
  authError: null,
  joinUser: null,
  joinError: null,
  reqCertEmail: null,
  reqCertEmailError: null,
  checkCert: null,
  checkCertError: null,
  findEmail: null,
  findEmailError: null,
  changePw: null,
  changePwError: null,
};

const auth = handleActions(
  {
    /*
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, draft => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
    }),
    */
    [INITIALIZE_AUTH]: (state) => ({
      ...state,
      auth: null,
      authError: null,
    }),
    [LOGIN_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth: auth,
    }),
    [LOGIN_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [JOIN_SUCCESS]: (state, { payload: join }) => ({
      ...state,
      joinError: null,
      joinUser: join,
    }),
    [JOIN_FAILURE]: (state, { payload: error }) => ({
      ...state,
      joinError: error,
    }),
    [CERTIFY_SUCCESS]: (state, { payload: certify }) => ({
      ...state,
      reqCertEmailError: null,
      reqCertEmail: certify,
    }),
    [CERTIFY_FAILURE]: (state, { payload: error }) => ({
      ...state,
      reqCertEmailError: error,
    }),
    [CHECK_CERT_SUCCESS]: (state, { payload: checkCert }) => ({
      ...state,
      checkCertError: null,
      checkCert: checkCert,
    }),
    [CHECK_CERT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      checkCertError: error,
    }),
    [FIND_SUCCESS]: (state, { payload: find }) => ({
      ...state,
      findEmailError: null,
      findEmail: find,
    }),
    [FIND_FAILURE]: (state, { payload: error }) => ({
      ...state,
      findEmailError: error,
    }),
    [CHANGE_PW_SUCCESS]: (state, { payload: change }) => ({
      ...state,
      changePwError: null,
      changePw: change,
    }),
    [CHANGE_PW_FAILURE]: (state, { payload: error }) => ({
      ...state,
      changePwError: error,
    }),
  },
  initialState,
);

export default auth;
