import gql from 'graphql-tag';

export const GET_STUDENTS = gql`
  query userStudents($classId: Int!, $limit: Int!, $offset: Int!) {
    userStudents(classId: $classId, limit: $limit, offset: $offset) {
      ID
      NUM
      NAME
      GENDER
      PASSWORD
      CLASS_ID
      STATUS
      CREATE_AT
      UPDATE_AT
    }
  }
`;

export const GET_STUDENTS_ETESTS = gql`
  query userStudents($classId: Int!, $limit: Int!, $offset: Int!) {
    userStudents(classId: $classId, limit: $limit, offset: $offset) {
      ID
      NUM
      NAME
      GENDER
      CLASS_ID
      STATUS
      userGroupClass {
        ID
        CLASS_NAME
        CLASS_GRADE
        USER_GROUP_ID
        userGroupOrder {
          ID
        }
      }
      testUserGroups {
        TEST_INFO_ID
        testInfo {
          STATUS
        }
        resultScore {
          LQ
        }
        INSERT_DATE
      }
      CREATE_AT
      UPDATE_AT
    }
  }
`;

export const ISLOGIN = gql`
  query isLogin {
    isLogin {
      ID
      NUM
      NAME
      GENDER
      PASSWORD
      CLASS_ID
      STATUS
      CREATE_AT
      UPDATE_AT
    }
  }
`;
