import { createAction, handleActions } from 'redux-actions';

const INITIAL_CLASS = 'class/INITIAL_CLASS';
const SELECTED_CLASS = 'class/SELECTED_CLASS';

export const initialClass = createAction(INITIAL_CLASS);
export const selectedClass = createAction(SELECTED_CLASS, (row) => row);

const initialState = {
  selectedClass: null,
};

const classe = handleActions(
  {
    [INITIAL_CLASS]: (state) => ({ ...state, selectedClass: null }),
    [SELECTED_CLASS]: (state, { payload: row }) => ({
      ...state,
      selectedClass: { ...row },
    }),
  },
  initialState,
);

export default classe;
